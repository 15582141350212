import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./styles/Home.css";

import MinilogoNa from "../images/SB-logoNa.svg";
import OutlineCCLogo_Blanco from "../images/OutlineCCLogo_Blanco.svg";
import OutlineCCLogo_Negro from "../images/OutlineCCLogo_Negro.svg";
import logoSB from "../images/LogoSketchBattle.svg";
import Murall from "../images/Murall.svg";
import Aku from "../images/logo_aku.png";

import Personaje1 from "../images/Personaje1.svg";
import flare from "../images/flare.svg";
import DosCategorias from "../images/2Categorias.svg";
import SBpicture01 from "../images/A-roll_Moment_01.png";
import SBpicture02 from "../images/A-roll_Moment_02.png";
import SB_Premios from "../images/SB_Premios.png";
import PremioBlast from "../images/PremioBlast.svg";
import PremioPro from "../images/PremioPro.svg";

import PersonajeDudaM from "../images/PersonajeDudaM.svg";
import PersonajeDudaH from "../images/PersonajeDudaH.svg";
import AdminRespondeL from "../images/AdminRespondeL.svg";
import AdminRespondeR from "../images/AdminRespondeR.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointDown as fasfaHandPD } from "@fortawesome/free-solid-svg-icons";
import { faUser as fasUser } from "@fortawesome/free-solid-svg-icons";
import { faUsers as fasUsers } from "@fortawesome/free-solid-svg-icons";
import { faHandshake as fasHandshake } from "@fortawesome/free-solid-svg-icons";
import { faDice as fasDice } from "@fortawesome/free-solid-svg-icons";
import { faTrophy as fasTrophy } from "@fortawesome/free-solid-svg-icons";
import { faTimes as fasTimes } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as fasEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare as fasFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagram as fasInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitch as fasTwitch } from "@fortawesome/free-brands-svg-icons";
import { faDiscord as fasDiscord } from "@fortawesome/free-brands-svg-icons";

export default class Home extends Component {
  render() {
    return (
      <header className="masthead">
        <div className="Home text-center" id="page-top">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-5 col-md-8 col-sm-9 col-10 align-self-center">
                <img
                  className="img-fluid pt-4 mb-2"
                  src={logoSB}
                  alt="logoSB"
                />
              </div>

              <div className="col-lg-10 col-md-8 col-sm-8 col-10 align-self-center">
                <p className="Home__tituloNegro text-uppercase">
                  29 - 31 de Octubre 2020
                </p>
                <p className="Home__tituloBlanco text-uppercase">
                  Torneo de Dibujo
                </p>
                <a
                  className="btn btn-dark rounded-pill"
                  href="https://www.facebook.com/events/352928112707011"
                >
                  <span className="Home__cta text-uppercase">Ir al evento</span>
                </a>
                <p className="Home__parrafoBlanco page-section-heading text-center text-uppercase mt-4">
                  Entradas agotadas, ¡muchas gracias!
                </p>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-8 col-12 align-self-center">
                <img
                  className="img-fluid pt-4"
                  src={Personaje1}
                  alt="Personaje1"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Categorias Section--> */}
        <section className="page-section Categorias" id="Categorias">
          <div className="container">
            <div className="row h-100 align-items-center justify-content-center text-center">
              {/* <!-- Categorias Section Heading--> */}
              <div className="col-lg-12 col-md-12 col-sm-2 col-3 align-self-center">
                <img className="Home__Flare py-5" src={flare} alt="flare" />
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
                <p className="Home__parrafoBlanco page-section-heading text-center text-uppercase mb-0">
                  REGíSTRATE Y PARTICIPA EN LA PRIMERA
                  <br />
                  VERSIÓN ONLINE DE SKETCH BATTLE
                </p>
              </div>

              <div className="col-lg-7 col-md-9 col-sm-9 col-12 align-self-center">
                <img
                  className="img-fluid py-5"
                  src={DosCategorias}
                  alt="DosCategorias"
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center py-2">
                <p className="Home__tituloNegro page-section-heading text-center text-uppercase">
                  BLAST
                </p>
                <p className="Home__tituloBlanco page-section-heading text-center text-uppercase">
                  GRATUITA Y DIVERTIDA
                </p>
                <Link
                  className="btn btn-dark rounded-pill"
                  to="/"
                  data-toggle="modal"
                  data-target="#BlastModal"
                >
                  <span className="Home__Detalles">Detalles</span>
                </Link>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center py-2">
                <p className="Home__tituloNegro page-section-heading text-center text-uppercase">
                  PRO
                </p>
                <p className="Home__tituloBlanco page-section-heading text-center text-uppercase">
                  DE PAGO Y DESAFIANTE
                </p>
                <Link
                  className="btn btn-dark rounded-pill"
                  to="/"
                  data-toggle="modal"
                  data-target="#ProModal"
                >
                  <span className="Home__Detalles">Detalles</span>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Premios Section--> */}
        <section className="page-section Premios" id="Premios">
          <div className="container">
            <div className="row h-100 align-items-center justify-content-center text-center">
              {/* <!-- Categorias Section Heading--> */}
              <div className="col-lg-12 col-md-12 col-sm-2 col-3 align-self-center">
                <img className="Home__Flare py-5" src={flare} alt="flare" />
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-12 align-self-center"></div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center">
                <img
                  className="Home__PngTitulos mb-3"
                  src={SB_Premios}
                  alt="Personaje1"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12 align-self-center"></div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center py-4">
                <p className="Home__tituloNegro page-section-heading text-center text-uppercase">
                  Categoría
                </p>
                <p className="Home__tituloBlanco page-section-heading text-center text-uppercase">
                  Blast
                </p>

                <img
                  className="Home__PremiosPicture"
                  src={PremioBlast}
                  alt="PremioBlast"
                />
              </div>

              <div className="divider-custom">
                <div className="divider-custom-line3"></div>
                <div className="divider-custom-icon3">
                  <FontAwesomeIcon icon={fasTrophy} />
                </div>
                <div className="divider-custom-line3"></div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center py-4">
                <p className="Home__tituloNegro page-section-heading text-center text-uppercase">
                  Categoría
                </p>
                <p className="Home__tituloBlanco page-section-heading text-center text-uppercase">
                  Pro
                </p>

                <img
                  className="Home__PremiosPicture "
                  src={PremioPro}
                  alt="PremiosPicture"
                />
              </div>
            </div>
          </div>
        </section>

         {/* <!-- Organizado por Section--> */}
         <section className="page-section OrganizadoX" id="OrganizadoX">
          <div className="container">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-3 col-md-3 col-sm-12 col-12 align-self-center"></div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center mt-5 mb-3">
                <p className="Home__parrafoNegro page-section-heading text-center text-uppercase">
                  Sketch Battle es organizado <br />x
                </p>
                <img
                  className="Home__LogoOutline mb-4"
                  src={OutlineCCLogo_Negro}
                  alt="LogoOutline"
                />
                <Link className="btn btn-dark rounded-pill" to="/nosotros">
                  <span className="Home__Detalles">Conocer más</span>
                </Link>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-12 align-self-center"></div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-12 align-self-center"></div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center mt-5">
                <p className="Home__parrafoNegro page-section-heading text-center text-uppercase">
                  patrocinadores <br />-
                </p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-12 align-self-center"></div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-6 align-self-center mx-3 mb-5">
                <img className="img-fluid" src={Murall} alt="Murall" />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6 align-self-center mx-3 mb-5">
                <img className="img-fluid" src={Aku} alt="Aku Store" />
              </div>
            </div>
          </div>
        </section>

        <footer className="footer text-center">
          <div className="container">
            <div className="row">
              {/* <!-- Footer Location--> */}
              <div className="col-lg-2 col-md-2 col-sm-12 col-12 align-self-center my-5">
                <img className="img-fluid" src={OutlineCCLogo_Blanco} alt="" />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center my-4">
                <p className="Home__FooterTextTitulo text-uppercase mb-2">
                  Acerca de Nosotros
                </p>
                <p className="Home__FooterText mb-0 text-left">
                  Outline <br />
                  Apasionados de conectar con la comunidad creativa,
                  desarrollando espacios y plataformas que impulsen el talento
                  mexicano.
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 align-self-center my-4">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <p className="Home__FooterTextTitulo text-uppercase mb-2">
                      Contacto
                    </p>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 align-self-center">
                    <div className="footer-custom-icon text-center">
                      <FontAwesomeIcon icon={fasEnvelope} />
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-10 col-10 align-self-center">
                    <p className="Home__FooterText mb-0 text-left">
                      hola.outline@gmail.com
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-12 col-12 align-self-center text-center my-4">
                <div className="row align-self-center text-center">
                  <div className="footer-custom-icon col-lg-3 col-md-3 col-sm-3 col-3 align-items-center justify-content-center text-center">
                    <a href="https://www.facebook.com/outlinecc">
                      <FontAwesomeIcon icon={fasFacebookSquare} />
                    </a>
                  </div>
                  <div className="footer-custom-icon col-lg-3 col-md-3 col-sm-3 col-3 align-items-center justify-content-center text-center">
                    <a href="https://www.instagram.com/outlinecc/">
                      <FontAwesomeIcon icon={fasInstagram} />
                    </a>
                  </div>
                  <div className="footer-custom-icon col-lg-3 col-md-3 col-sm-3 col-3 align-items-center justify-content-center text-center">
                    <a href="https://www.twitch.tv/outlinecc">
                      <FontAwesomeIcon icon={fasTwitch} />
                    </a>
                  </div>
                  <div className="footer-custom-icon col-lg-2 col-md-3 col-sm-3 col-3 align-items-center justify-content-center text-center">
                    <a href="https://discord.gg/mB5g3BD">
                      <FontAwesomeIcon icon={fasDiscord} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/*MODAL BLAST style: Display:None; */}
        <div
          className="blast-modal modal fade"
          id="BlastModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="BlastModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div>
                <img className="img-fluid" src={SBpicture01} alt="" />
              </div>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <div className="divider-custom-icon">
                    <FontAwesomeIcon icon={fasTimes} />
                  </div>
                  {/* <!-- <i className="fas fa-times"></i> --> */}
                </span>
              </button>
              <div className="modal-body text-center">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      {/* <!-- blast Modal - Title--> */}
                      <p
                        className="blast-modal-title text-uppercase my-3"
                        id="BlastModalLabel"
                      >
                        sketch battle blast
                      </p>
                    </div>

                    {/* <!-- blast Modal - QUESTION--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={PersonajeDudaH}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-titleOrange text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        Pero...
                        <br />
                        ¿Cómo se juega?
                        <br />
                        ¿Cuáles son las reglas?
                      </p>
                    </div>

                    {/* <!-- blast Modal - ANSWER--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-title text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        Lee los siguientes pasos...
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={AdminRespondeR}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="divider-custom">
                  <div className="divider-custom-line"></div>
                  <div className="divider-custom-icon">
                    <FontAwesomeIcon icon={fasfaHandPD} />
                  </div>
                  <div className="divider-custom-line"></div>
                </div>

                {/* <!-- Step 01 Modal BLAST- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasUser} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">1</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/" disabled={true}>
                        <span className="Home__ModalTextStep text-uppercase">
                          Regístrate
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                    Update. Las entradas están agotadas, ¡muchas gracias por su preferencia! Pueden acceder al evento  
                    <a className="enlaces" href="https://www.facebook.com/events/352928112707011"> aquí</a> 
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 01 Modal BLAST - Text--> */}

                {/* <!-- Step 02 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasUsers} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">2</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Seleccionados
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      La selección en la categoría Blast, será aleatoria. Los
                      seleccionados serán publicados en las redes sociales y
                      serán notificados por correo electrónico.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 02 Modal BLAST - Text--> */}

                {/* <!-- Step 03 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom mt-4 mb-3">
                    <div className="divider-custom-line2"></div>
                    <div>
                      <img src={MinilogoNa} alt="MiniLogoNa" />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">3</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Torneo
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Sketch Battle es un torneo de dibujo digital de 32
                      personas, con un sistema de eliminación y partidas de 4 a
                      2 personas. El torneo será transmitido en Facebook Live,
                      Instagram Live y/o Twitch.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 03 Modal BLAST - Text--> */}

                {/* <!-- Step 04 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasHandshake} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">4</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          batalla
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Compite dibujando en digital, contra 1 o 3 adversarios, en
                      una partida de 12 minutos. En cada partida, se anuncia un
                      tema diferente, el cual tendrás que dibujar. El ganador o
                      ganadores de cada partida serán seleccionados por 3 jueces
                      calificadores. La plataforma en la cual los artistas
                      dibujarán digitalmente será Aggie.io
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 04 Modal BLAST - Text--> */}
                {/* <!-- Step 05 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasDice} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">5</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          modificadores blast
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Los modificadores blast, buscan generar una mecánica de
                      juego mas interesante y divertida, que pondrá a prueba tus
                      habilidades creativas.
                    </p>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      - Cambia al color azul.
                      <br />
                      - Haz Flip Horizontally (H).
                      <br />
                      - Rota el canvas 90°
                      <br />
                      - Cambia a la herramienta de Lápiz (P) puedes modificarla.
                      <br />- Agrega fuego.
                    </p>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Estos modificadores solo estarán activos 3 minutos durante
                      la partida. Estos son un ejemplo, la lista final será
                      publicada el día de la competencia. Los modificadores se
                      seleccionarán aleatoriamente en algún momento sorpresa
                      durante la partida.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 05 Modal BLAST - Text--> */}
                {/* <!-- Step 06 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasTrophy} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">6</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Premios
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      En la categoría SB Blast, podrá haber premios por parte de
                      patrocinadores, los cuales se irán anunciando conforme se
                      acerque la fecha.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 06 Modal BLAST - Text--> */}

                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={AdminRespondeL}
                        alt=""
                      />
                    </div>

                    {/* <!-- blast Modal - ANSWER--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-title text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        ¿Más dudas?
                        <br />
                        Escribe a nuestras Redes Sociales
                      </p>
                    </div>

                    <div className="col-lg-12">
                      {/* <!-- blast Modal - Title--> */}
                      <p
                        className="blast-modal-titleOrange text-uppercase my-4"
                        id="BlastModalLabel"
                      >
                        ¿Estas listo o lista?
                      </p>
                    </div>
                    <div className="col-lg-12">
                      <a
                      className="btn btn-dark rounded-pill mb-2 noclick"
                      href="https://sketch-battle-blast-2020.eventbrite.com.mx">
                        <span className="Home__cta text-uppercase">
                        Entradas Agotadas!
                        </span>
                      </a>
                    </div>
                    <div className="col-lg-12">
                      <p className="text-Registro-12 pb-4">
                        Al hacer click en "Participar" certifico que tengo 16
                        años o más y acepto cumplir el reglamento.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*MODAL PRO style: Display:None; */}

        <div
          className="blast-modal modal fade"
          id="ProModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ProModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div>
                <img className="img-fluid" src={SBpicture02} alt="" />
              </div>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <div className="divider-custom-icon">
                    <FontAwesomeIcon icon={fasTimes} />
                  </div>
                  {/* <!-- <i className="fas fa-times"></i> --> */}
                </span>
              </button>
              <div className="modal-body text-center">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      {/* <!-- PRO Modal - Title--> */}
                      <p
                        className="blast-modal-title text-uppercase my-3"
                        id="BlastModalLabel"
                      >
                        sketch battle pro
                      </p>
                    </div>

                    {/* <!-- PRO Modal - QUESTION--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={PersonajeDudaM}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-titleOrange text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        Y ¿Cómo hago mi registro?
                        <br />
                        ¿Cuáles son las reglas?
                      </p>
                    </div>

                    {/* <!-- PRO Modal - ANSWER--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-title text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        Lee los siguientes pasos...
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={AdminRespondeR}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="divider-custom">
                  <div className="divider-custom-line"></div>
                  <div className="divider-custom-icon">
                    <FontAwesomeIcon icon={fasfaHandPD} />
                  </div>
                  <div className="divider-custom-line"></div>
                </div>

                {/* <!-- Step 01 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasUser} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">1</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Regístrate
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                    Update. Con el fin de proveer una experiencia completa, realizamos un pronóstico de ventas 
                    para asegurarnos que estuvieran cubiertos los lugares de Sketch Battle Pro, y notamos que 
                    iban a faltar lugares. Por lo tanto, hemos decidido cancelar la versión Pro, y realizar un 
                    reembolso de los boletos que hayan comprado. <br/> Nos hemos puesto en contacto con aquellos que hayan 
                    comprado su boleto. <br/>
                    Mientras tanto, puedes acceder al evento en Facebook 
                    <a className="enlaces" href="https://www.facebook.com/events/352928112707011"> aquí</a> 
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 01 Modal PRO- Text--> */}

                {/* <!-- Step 02 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasUsers} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">2</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Seleccionados
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Hay un límite 32 participantes, por lo tanto solo hay 32
                      entradas para participar en la categoría SB Pro. Los cupos
                      se irán llenando conforme las personas vayan pagando.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 02 Modal PRO- Text--> */}

                {/* <!-- Step 03 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom mt-4 mb-3">
                    <div className="divider-custom-line2"></div>
                    <div>
                      <img src={MinilogoNa} alt="MiniLogoNa" />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">3</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Torneo
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Sketch Battle es un torneo de dibujo digital de 32
                      personas, con un sistema de eliminación y partidas de 4 a
                      2 personas. El torneo será transmitido en Facebook Live,
                      Instagram Live y/o Twitch.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 03 Modal PRO- Text--> */}

                {/* <!-- Step 04 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasHandshake} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">4</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          batalla
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Compite dibujando en digital, contra 1 o 3 adversarios, en
                      una partida de 12 minutos. En cada partida, se anuncia un
                      tema diferente, el cual tendrás que dibujar. El ganador o
                      ganadores de cada partida serán seleccionados por 3 jueces
                      calificadores. La plataforma en la cual los artistas
                      dibujarán digitalmente será Aggie.io La categoría SB Pro,
                      no cuenta con modificadores que alteren la partida.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 04 Modal PRO- Text--> */}

                {/* <!-- Step 05 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasTrophy} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">5</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Premios
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Los premios para la categoría SB Pro, es un premio mayor
                      para el primer lugar de $4000 MXN. Sin embargo, puede
                      haber premios extra por parte de patrocinadores, estos se
                      irán anunciando conforme se acerque la fecha de la
                      competencia.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 04 Modal PRO- Text--> */}

                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={AdminRespondeL}
                        alt=""
                      />
                    </div>

                    {/* <!-- PRO Modal - ANSWER--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-title text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        ¿Más dudas?
                        <br />
                        Escribe a nuestras Redes Sociales
                      </p>
                    </div>

                    <div className="col-lg-12">
                      {/* <!-- PRO Modal - Title--> */}
                      <p
                        className="blast-modal-titleOrange text-uppercase my-4"
                        id="BlastModalLabel"
                      >
                        ¿Estas listo o lista?
                      </p>
                    </div>
                    <div className="col-lg-12">
                    <a
                      className="btn btn-dark rounded-pill mb-2 noclick"
                      href="https://sketch-battle-pro-2020.eventbrite.com.mx">
                        <span className="Home__cta text-uppercase">
                          Participa
                        </span>
                      </a>
                    </div>
                    <div className="col-lg-12">
                      <p className="text-Registro-12 pb-4">
                        Al hacer click en "Participar" certifico que tengo 18
                        años o más y acepto cumplir el reglamento.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
