import React, { Component } from "react";

import "./styles/Registro.Usuario.css";

import SB_Evento01 from "../images/SB_Evento01.png";
import SB_Evento02 from "../images/SB_Evento02.png";
import SB_Evento03 from "../images/SB_Evento03.png";
import SB_Evento04 from "../images/SB_Evento04.png";
import SB_Evento05 from "../images/SB_Evento05.png";
import SB_Evento06 from "../images/SB_Evento06.png";
import SB_Evento07 from "../images/SB_Evento07.png";
import SB_Evento08 from "../images/SB_Evento08.png";

export default class RegistroUsuario extends Component {
  render() {
    return (
      <header className="masthead__nosotros">
        <div className="Home text-center" id="page-top">
          <div className="Nosotros__Titulo align-items-center justify-content-center text-center">
            <p className="Home__parrafoNegro text-uppercase mb-1">Nosotros</p>
            <p className="text-Registro-14 mt-1" id="NombreDePerfil">
              Sketch Battle es una iniciativa de Outline, impulsada con una
              finalidad recreativa
              <br /> y de sana convivencia, donde no se determina el valor de un
              Artista.
            </p>
          </div>

          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <p className="Home__ctaRegistrarse text-uppercase my-3">
                Eventos anteriores
              </p>
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="4"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="5"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="6"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="7"
                  ></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      className="d-block w-100"
                      src={SB_Evento04}
                      alt="First slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Junio 2019</h5>
                      <p>Casa Aomori, Mty N.L. México</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={SB_Evento02}
                      alt="Second slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Agosto 2019</h5>
                      <p>Casa Aomori, Mty N.L. México</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={SB_Evento01}
                      alt="Third slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Agosto 2019</h5>
                      <p>Casa Aomori, Mty N.L. México</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={SB_Evento05}
                      alt="Fourth slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Octubre 2019</h5>
                      <p>Casa Aomori, Mty N.L. México</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={SB_Evento03}
                      alt="Fifth slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Noviembre 2019</h5>
                      <p>Casa Aomori, Mty N.L. México</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={SB_Evento06}
                      alt="Sixth slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Noviembre 2019</h5>
                      <p>Casa Aomori, Mty N.L. México</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={SB_Evento07}
                      alt="Seventh slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Agosto 2019</h5>
                      <p>Casa Aomori, Mty N.L. México</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={SB_Evento08}
                      alt="Eighth slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Agosto 2019</h5>
                      <p>Casa Aomori, Mty N.L. México</p>
                    </div>
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
