import React, { Component } from "react";
import "./styles/Registro.Usuario.css";

export default class SBFAQ extends Component {
  render() {
    return (
      <header className="masthead__nosotros">
        <div className="Home text-center" id="page-top">
          <div className="Nosotros__Titulo align-items-center justify-content-center text-center">
            <p className="Home__parrafoNegro text-uppercase mb-1">Preguntas Frecuentes</p>
            <p className="text-Registro-14 mt-1" id="">
              He aquí las preguntas frecuentes sobre Sketch Battle. <br />
              Esta sección estará en expansión según las preguntas de la audiencia.
            </p>
          </div>

          <div className="container h-100">
            <div className="row h-100 align-items-left justify-content-left text-left">
              <p className="Perfil__BioText my-5" id="">
                Q1: ¿De qué trata Sketch Battle?
                <br />
                <br />
                - Es una competencia amistosa de dibujo, en la cual debes ilustrar 
                (bajo límite de tiempo) una idea según el tema que presentemos en 
                la partida. Dividimos a los participantes en rounds, que empiezan 
                generalmente con batallas campales (partidas de 4 artistas), y 
                terminan en batallas uno contra uno.
                <br />
                <br />
                Q2: ¿Cómo me inscribo?
                <br />
                <br />
                - <strong>Las inscripciones se realizan empezando por nuestra página web</strong> 
                <a href="https://sketchbattle.cc"> (https://sketchbattle.cc), </a> 
                seleccionando una de las dos 
                categorías: <br /> Blast o Pro. Terminando de leer las instrucciones, 
                ingresa al enlace de inscripción.
                <br />
                <br />
                Q3: ¿Qué son las categorías Blast y Pro?
                <br />
                <br />
                - <strong>Blast es la versión gratuita de Sketch Battle.</strong> Tiene modificadores 
                en cada round que hacen más entretenida la experiencia, y los 
                premios dependen de los patrocinadores. <br />
                - <strong>Pro es la versión de pago y desafiante.</strong> Tiene un premio final 
                de $4,000 MXN y no tiene modificadores.
                <br />
                <br />
                Q4: ¿Puedo registrarme en las dos categorías?
                <br />
                <br />
                - <strong>No</strong>, solamente elige una.
                <br />
                <br />
                Q5: ¿En dónde hago los dibujos?
                <br />
                <br />
                - Todos los participantes tendrán que usar la aplicación web gratuita Aggie 
                <a href="https://aggie.io"> (https://aggie.io) </a>, usando cualquier navegador web compatible.
                <br />
                <br />
                Q6: ¿Puedo usar una tableta digitalizadora?
                <br />
                <br />
                - Claro. <strong>Te sugerimos probarla antes</strong>, ¡pero sería ideal que la uses!
                <br />
                <br />
                Q7: ¿Cómo califican los dibujos en cada ronda?
                <br />
                <br />
                - Tendremos jueces, quienes al final de cada ronda califican lo siguiente: <br />
                <strong>Idea, creatividad, composición, anatomía, y color.</strong> <br />
                Y recuerda, se trata de hacer un sketch (boceto). Así que, ¡deja fluir los trazos!
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
