import React, { Component } from "react";
import "./styles/Registro.Usuario.css";

export default class RegistroUsuario extends Component {
  render() {
    return (
      <header className="masthead__nosotros">
        <div className="Home text-center" id="page-top">
          <div className="Nosotros__Titulo align-items-center justify-content-center text-center">
            <p className="Home__parrafoNegro text-uppercase mb-1">Reglas</p>
            <p className="text-Registro-14 mt-1" id="">
              Es importante que leas las reglas para que la experiencia sea la
              mejor para todos, <br />
              si tienes alguna duda, no dudes en contactarnos.
            </p>
          </div>

          <div className="container h-100">
            <div className="row h-100 align-items-left justify-content-left text-left">
              <p className="Perfil__BioText my-5" id="">
                1. Información general <br />
                <br />
                Sketch Battle («SB» o la «Competición») es un torneo de dibujo
                digital, donde los participantes compiten en un sistema de
                eliminación con partidas que se conforman por 4 (Todos contra
                todos) y 2 personas (Uno contra uno), cada partida cuenta con un
                tema de dibujo diferente, en ambas categorías («SB Blast» y «SB
                Pro»).
                <br />
                <br />
                Sketch Battle es una iniciativa de Outline («Organizador») con
                una finalidad recreativa y de sana convivencia, donde no se
                determina el valor de un Artista.
                <br />
                <br />
                2. Requisitos de los participantes
                <br />
                <br />
                Los interesados en participar deben registrarse en
                www.sketchbattle.cc para poder aplicar a una de las dos
                categorías del torneo (SB Blast o SB Pro). Los Artistas que
                cumplan los requisitos descritos en esta sección (a título
                individual, «Artista» o «Competidor») deberán:
                <br />
                <br />
                - Disponer de una computadora con los requerimientos compatibles
                de Aggie.io, los cuales se encuentran en el siguiente enlace
                https://aggie.io/help.
                <br />
                - Sugerimos disponer del hardware necesario para la Competición
                (Mouse, Tablet digitalizadora, Audífonos, Webcam, etc.) aunque
                este no es obligatorio.
                <br />
                - Aceptar las Condiciones de Uso y la Política de Privacidad de
                SB disponible en www.sketchbattle.cc/condicionesdeuso
                <br />
                - Para la categoría Blast, será para edades de 16 años 
                en adelante al momento del registro.
                <br />
                - Para la categoría Pro, será solamente para mayores de 18 años
                al momento del registro.
                <br />
                - Ser miembro en el servidor de Outline en Discord, esto para la
                comunicación durante el torneo, para unirte accede al siguiente
                enlace https://discord.gg/tTkzQ4A. <br />
                <br />
                3. Estructura del torneo
                <br />
                <br />
                En cada categoría del Sketch Battle participan 32 personas, las
                cuales compiten en un sistema de eliminatorias de la siguiente
                forma:
                <br />
                <br />
                - 16avos (Ronda de 32): Se compite en grupos de 4, todos contra
                todos y salen 2 ganadores por grupo.
                <br />
                - Octavos (Ronda de 16): Se compite en grupos de 4, todos contra
                todos y salen 2 ganadores por grupo.
                <br />
                - Cuartos (Ronda de 8): Se compite uno contra uno, sale un
                ganador.
                <br />
                - Semifinal (Ronda de 4): Se compite uno contra uno, sale un
                ganador.
                <br />
                - Final (Ronda de 2): Se compite uno contra uno, sale el ganador
                del torneo.
                <br />
                <br />
                Cada partida tiene una duración de 12 minutos, con la
                posibilidad de tener un tiempo extra de 1 a 3 minutos, ese
                tiempo extra será asignado por los moderadores.
                <br />
                <br />
                Los ganadores de cada ronda serán anunciados por los 3 jueces
                (Jueces pendientes de anunciar) hasta finalizar todas las
                partidas de esa misma ronda.
                <br />
                <br />
                4. Costos
                <br />
                <br />
                Para la categoría SB Blast, no hay ningún costo..
                <br />
                <br />
                Para la categoría SB Pro, el costo de participación es $250 MXN
                (Sólo 32 boletos).
                <br />
                <br />
                <strong>En caso de no llenarse los lugares para la categoría SB Pro, </strong>
                los participantes recibirán una devolución de su dinero, y serán transferidos 
                a SB Blast de manera segura. <br />
                No tendrán la oportunidad del premio original de SB Pro, pero si a los premios 
                correspondientes a SB Blast.
                <br />
                <br />
                5. Fechas y horarios
                <br />
                <br />
                Ambas categorías del Sketch Battle (SB Blast y SB Pro) se
                llevarán a cabo los días 29, 30 y 31 de Octubre del año 2020, a
                través de diferentes plataformas en internet.
                <br />
                <br />
                Las rondas sucederán en un horario nocturno, desde las 19:00
                horas hasta las 23:00 horas, considerando que cada jugador solo
                necesita dedicar 30 minutos por partida, a la hora en la cual le
                toca competir (Estar presente vía Discord 15 minutos antes de la
                partida).
                <br />
                <br />
                5.1 Fechas de registro:
                <br />
                <br />
                Los interesados en participar en la categoría SB Blast deben
                registrarse en <a href="https://sketch-battle-blast-2020.eventbrite.com.mx">https://sketch-battle-blast-2020.eventbrite.com.mx</a> del 30 de Septiembre a las 19:00
                CDT al 13 de Octubre del año 2020 a las 23:59 CDT.
                <br />
                <br />
                Los interesados en participar en la categoría SB Pro deben
                registrarse en <a href="https://sketch-battle-pro-2020.eventbrite.com.mx">https://sketch-battle-pro-2020.eventbrite.com.mx</a> del 30 de Septiembre a las 19:00
                CDT al 20 de Octubre del año 2020 a las 23:59 CDT.
                <br />
                <br />
                6. Cupos y Selección
                <br />
                <br />
                La categoría SB Blast tiene un cupo para 32 competidores en el
                torneo, sin embargo no hay límite en la cantidad de personas que
                deseen participar y apliquen en la convocatoria, para esto se
                hará una selección aleatoria y de esta forma tener a los 32
                participantes entre todos los que aplicaron.
                <br />
                <br />
                La categoría SB Pro tiene un cupo para 32 competidores en el
                torneo, los cuales se irán llenando conforme realicen su pago
                ($250 MXN) al inscribirse.
                <br />
                <br />
                Todos los participantes seleccionados recibirán una confirmación
                vía correo electrónico.
                <br />
                <br />
                En caso de que uno o más seleccionados se den de baja en
                cualquiera de las dos categorías, se publicarán las vacantes a
                través de las redes sociales de Outline, y se enviará un correo
                a las personas que no fueron seleccionadas de forma aleatoria,
                para volver a elegir.
                <br />
                <br />
                7. Código de conducta
                <br />
                <br />
                Los competidores deberán comportarse de forma razonable y
                mantener un semblante adecuado y respetuoso ante los
                espectadores, administradores del torneo y el resto de
                competidores. Estos requisitos se aplican a las interacciones
                online y sin conexión e incluyen los comportamientos en redes
                sociales y las actividades en retransmisiones en directo. Entre
                las conductas prohibidas se incluyen las siguientes:
                <br />
                <br />
                - Infringir cualquier ley, regla o normativa aplicable, según el
                Organizador determine a su exclusivo criterio.
                <br />
                - Utilizar cualquier software o programa que dañe, interfiera
                con o altere la Competición o la propiedad u ordenadores de
                otros.
                <br />
                - Utilizar cualquier software externo diseñado para proporcionar
                al Competidor una ventaja injusta.
                <br />
                - Interferir con o alterar la participación de otros jugadores
                en la Competición.
                <br />
                - Acosar, amenazar, intimidar, utilizar un lenguaje ofensivo,
                enviar reiteradamente mensajes no deseados, realizar
                declaraciones a través del dibujo ya sea de forma implícita o
                explícita, además de ataques personales relacionados con la
                raza, sexo, orientación sexual, religión, orígenes, etc.
                <br />
                - Usar vulnerabilidades, trampas, características no
                documentadas, problemas o errores de diseño en la Competición.
                <br />
                - Participar en cualquier actividad que altere de forma
                significativa el entorno de juego pacífico, justo y respetuoso
                de la Competición.
                <br />
                - Promover, alentar o participar en cualquiera de las
                actividades prohibidas descritas anteriormente.
                <br />
                <br />
                8. Sanciones
                <br />
                <br />
                La infracción de cualquier parte de este Código de conducta
                resultará, según el Organizador decida, en sanciones y/o pérdida
                de la condición de ganador. Todas las decisiones y resoluciones
                del Organizador en relación con la Competición serán definitivas
                y vinculantes. El Organizador se reserva el derecho de penalizar
                a cualquier Artista de la Competición de cualquier nivel, en
                cualquier momento y por cualquier razón. Las penalizaciones
                pueden incluir, sin un orden concreto, lo siguiente:
                <br />
                <br />
                - Advertencia
                <br />
                - Última advertencia
                <br />
                - Retirada de la victoria de una partida
                <br />
                - Retirada de la victoria de todas las partidas
                <br />
                - Retirada de premios (incluido el dinero del premio)
                <br />
                - Expulsión del Sketch Battle
                <br />
                - Descalificación del Sketch Battle y de futuras competiciones
                <br />
                <br />
                9. Premios
                <br />
                <br />
                La categoría SB Blast (Gratuita), puede o no tener premios los
                cuales están pendientes de anunciarse, estos serían por parte de
                los patrocinadores de la Competición. <br />
                La categoría SB Pro (Costo $250 MXN), tiene un premio mayor de
                $4,000 MXN, para el 1er lugar. Además puede o no tener otros
                premios pendientes de anunciarse, estos serían por parte de los
                patrocinadores de la Competición. <br />
                <br />
                9.1 Entrega de premios
                <br />
                <br />
                La entrega del premio mayor de $4,000.00 MXN de la categoría SB
                Pro, se hará a través de dos transferencias bancarias a la
                cuenta bancaria que el ganador nos haya indicado.
                <br />
                <br />
                Los pagos serán programados 7 días después de la finalización del 
                evento. Retenemos de manera proactiva los pagos para garantizar que 
                haya fondos disponibles por disposición de Eventbrite, el servicio 
                usado para procesar los boletos de Sketch Battle.
                <br />
                <br />
                La entrega de otros premios por parte de los patrocinadores, se
                hará según el criterio de los mismos.
                <br />
                <br />
                10. Competencia
                <br />
                <br />
                Los Artistas deberán estar presentes en el servidor de Discord,
                15 minutos antes de que inicie la partida en la que ellos estén
                participando.
                <br />
                <br />
                Los enlaces a los canvas de Aggie.io serán individuales para
                cada Artista, estos no pueden ser compartidos a alguna otra
                persona y serán proporcionados por algún miembro administrador
                de SB.
                <br />
                <br />
                El uso de micrófono durante la partida no es obligatorio, sin
                embargo esto nos ayuda para tener mejor comunicación con los
                participantes, en caso de no contar con uno, será suficiente con
                estar en el canal de texto correspondiente a la partida.
                <br />
                <br />
                Está permitido el uso de short cuts o cualquier herramienta
                presente en Aggie.io (excepto importar imágenes o cualquier
                recurso gráfico).
                <br />
                <br />
                Todas las partidas serán publicadas en directo vía Facebook
                Live, Instagram Live y/o Twitch.
                <br />
                <br />
                La temática de las ilustraciones se dará a conocer al iniciar la
                partida, esta será diferente en cada partida y a otras partidas.
                <br />
                <br />
                10.1 Interrupciones
                <br />
                <br />
                En caso de que el internet de uno o ambos participantes falle
                durante la partida, se dará un tiempo de compensación para
                ambos, teniendo un límite de 3 minutos de compensación, en caso
                de que el internet falle por más tiempo o constantemente durante
                la partida, esta se moverá una hora después y se hará offscreen,
                siendo monitoreada por el equipo organizador. Esta regla aplica
                diferente en 16avos y 8vos, en este tipo de partida de 4
                participantes, en caso de que un participante no pueda volver a
                la partida, el encuentro se finalizará de igual forma y no se
                pospondrá, tomando las ilustraciones como terminadas.
                <br />
                <br />
                11. Derechos comerciales
                <br />
                <br />
                Todos los derechos comerciales como lo son el logo, imágenes
                publicitarias, etc. (incluidos, entre otros, los derechos de
                marketing y medios de comunicación) relacionados con el Sketch
                Battle pertenecen al Organizador.
                <br />
                <br />
                El Competidor consentirá el uso de las ilustraciones o dibujos
                que se hayan subido a la plataforma web de SB, además de los que
                se realicen durante la Competición, y renuncia a una recompensa
                sobre los materiales, los cuales serán usados únicamente con
                fines de marketing y medios de comunicación para promocionar el
                SB, así como para presentar al Artista en cuestión durante la
                Competición. El Organizador no se hace responsable en el caso de
                que un tercero tome el material y lo use con otros fines.
                <br />
                <br />
                El Artista siempre tendrá los derechos de autor de las piezas
                que se realicen durante la Competición.
                <br />
              </p>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
