import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import "./styles/Navbar.css";
import Minilogo from "../images/SB-logo.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars as fasBars } from "@fortawesome/free-solid-svg-icons";

$(function () {
  // Closes responsive menu when a scroll trigger link is clicked
  $(".js-scroll-trigger").click(function () {
    $(".navbar-collapse").collapse("hide");
  });

  // Activate scrollspy to add active class to navbar items on scroll
  $("body").scrollspy({
    target: "#mainNav",
    offset: 80,
  });
});

class Navbar extends React.Component {
  render() {
    return (
      <nav className="Navbar navbar-expand-lg fixed-top">
        <div className="container">
          <div className="navbar-header"></div>
          <div className="row">
            <Link
              className="Navbar__Menu-icon Navbar__Menu navbar-toggler navbar-toggler-right col-md-4 col-sm-4 col-4
                   align-items-center justify-content-center text-center"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              to="/"
            >
              <FontAwesomeIcon icon={fasBars} />
            </Link>

            <div className="col-lg-2 col-md-4 col-sm-4 col-4 align-items-center justify-content-center text-center">
              <Link
                className="navbar-brand js-scroll-trigger"
                href="#page-top"
                to="/"
              >
                <img className="navbar-brand__logo" src={Minilogo} alt="Logo" />
              </Link>
            </div>

            <div
              className="collapse navbar-collapse col-lg-8 align-items-center justify-content-center text-center"
              id="navbarResponsive"
            >
              <ul className="navbar-nav mx-auto">
                <li className="mx-0 my-3 mx-lg-1">
                  <Link
                    className="Navbar__links-item py-3 px-0 px-lg-3 rounded"
                    to="/Nosotros"
                  >
                    Nosotros
                  </Link>
                </li>
                <li className="mx-0 my-3 mx-lg-1">
                  <Link
                    className="Navbar__links-item py-3 px-0 px-lg-3 rounded"
                    to="/reglas"
                  >
                    Reglas
                  </Link>
                </li>
                <li className="mx-0 my-3 mx-lg-1">
                  <Link
                    className="Navbar__links-item py-3 px-0 px-lg-3 rounded"
                    to="/faq"
                  >
                    Preguntas Frecuentes
                  </Link>
                </li>
                <li className="mx-0 my-3 mx-lg-1">
                  <Link
                    className="Navbar__links-item py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                    to="/Contacto"
                  >
                    Contáctanos
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
