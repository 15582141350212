import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./styles/Registro.Usuario.css";
import logoSB from "../images/LogoSketchBattle.svg";
import MinilogoNa from "../images/SB-logoNa.svg";
import SBpicture01 from "../images/A-roll_Moment_01.png";
import SBpicture02 from "../images/A-roll_Moment_02.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointDown as fasfaHandPD } from "@fortawesome/free-solid-svg-icons";
import { faUser as fasUser } from "@fortawesome/free-solid-svg-icons";
import { faUsers as fasUsers } from "@fortawesome/free-solid-svg-icons";
import { faHandshake as fasHandshake } from "@fortawesome/free-solid-svg-icons";
import { faDice as fasDice } from "@fortawesome/free-solid-svg-icons";
import { faTrophy as fasTrophy } from "@fortawesome/free-solid-svg-icons";
import { faTimes as fasTimes } from "@fortawesome/free-solid-svg-icons";

import PersonajeDudaM from "../images/PersonajeDudaM.svg";
import PersonajeDudaH from "../images/PersonajeDudaH.svg";
import AdminRespondeL from "../images/AdminRespondeL.svg";
import AdminRespondeR from "../images/AdminRespondeR.svg";

export default class ConfirmaUsuario extends Component {
  render() {
    return (
      <header className="masthead__registro">
        <div className="Home text-center" id="page-top">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 align-self-center"></div>
              <div className="col-lg-4 col-md-4 col-sm-7 col-7 align-self-center">
                <img className="img-fluid my-4" src={logoSB} alt="logoSB" />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 align-self-center"></div>
              <div className="col-lg-1 col-md-1 col-sm-1 col-1 align-self-center"></div>
              <div className="col-lg-10 col-md-10 col-sm-10 col-10 align-self-center">
                <div className="bg-registro text-center project shadow-lg">
                  <p className="text-Titulo pt-3 text-uppercase">
                    Selecciona el torneo
                  </p>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8 mx-auto">
                        <p className="text-Registro-14 pt-4">
                          Recuerda leer los detalles de cada una de las
                          categorías, así como el reglamento.
                        </p>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-4">
                          <p className="Seleccion_SB_Na text-uppercase">
                            GRATUITA Y DIVERTIDA
                          </p>
                          <Link
                            className="btn btn-dark rounded-pill"
                            to="/"
                            data-toggle="modal"
                            data-target="#BlastModal"
                          >
                            <span className="Seleccion__Detalles text-uppercase">
                              Blast
                            </span>
                          </Link>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-4 pb-2 mb-4">
                          <p className="Seleccion_SB_Na text-center text-uppercase">
                            DE PAGO Y DESAFIANTE
                          </p>
                          <Link
                            className="btn btn-dark rounded-pill"
                            to="/"
                            data-toggle="modal"
                            data-target="#ProModal"
                          >
                            <span className="Seleccion__Detalles text-uppercase">
                              PRO
                            </span>
                          </Link>
                        </div>

                        <div id="success"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 col-1 align-self-center"></div>
            </div>
          </div>
        </div>
        <div
          className="blast-modal modal fade"
          id="BlastModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="BlastModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div>
                <img className="img-fluid" src={SBpicture01} alt="" />
              </div>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <div className="divider-custom-icon">
                    <FontAwesomeIcon icon={fasTimes} />
                  </div>
                </span>
              </button>
              <div className="modal-body text-center">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      {/* <!-- blast Modal - Title--> */}
                      <p
                        className="blast-modal-title text-uppercase my-3"
                        id="BlastModalLabel"
                      >
                        sketch battle blast
                      </p>
                    </div>

                    {/* <!-- blast Modal - QUESTION--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={PersonajeDudaH}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-titleOrange text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        Pero...
                        <br />
                        ¿Cómo se juega?
                        <br />
                        ¿Cuáles son las reglas?
                      </p>
                    </div>

                    {/* <!-- blast Modal - ANSWER--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-title text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        Lee los siguientes pasos...
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={AdminRespondeR}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="divider-custom">
                  <div className="divider-custom-line"></div>
                  <div className="divider-custom-icon">
                    <FontAwesomeIcon icon={fasfaHandPD} />
                  </div>
                  <div className="divider-custom-line"></div>
                </div>

                {/* <!-- Step 01 Modal BLAST- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasUser} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">1</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Regístrate
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                    Registra tu entrada <a className="enlaces" href="https://sketch-battle-blast-2020.eventbrite.com.mx">aquí </a> 
                    (o haciendo click en el botón al final de estas instrucciones) y 
                    finaliza tu registro llenando el formulario que aparecerá. 
                    Recibirás un correo electrónico de confirmación, ahora tendrás 
                    que esperar a ser seleccionado (SB Blast), entre todos los aplicantes.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 01 Modal BLAST - Text--> */}

                {/* <!-- Step 02 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasUsers} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">2</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Seleccionados
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      La selección en la categoría Blast, será aleatoria. Los
                      seleccionados serán publicados en las redes sociales y
                      serán notificados por correo electrónico.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 02 Modal BLAST - Text--> */}

                {/* <!-- Step 03 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom mt-4 mb-3">
                    <div className="divider-custom-line2"></div>
                    <div>
                      <img src={MinilogoNa} alt="MiniLogoNa" />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">3</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Torneo
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Sketch Battle es un torneo de dibujo digital de 32
                      personas, con un sistema de eliminación y partidas de 4 a
                      2 personas. El torneo será transmitido en Facebook Live,
                      Instagram Live y/o Twitch.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 03 Modal BLAST - Text--> */}

                {/* <!-- Step 04 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasHandshake} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">4</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          batalla
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Compite dibujando en digital, contra 1 o 3 adversarios, en
                      una partida de 12 minutos. En cada partida, se anuncia un
                      tema diferente, el cual tendrás que dibujar. El ganador o
                      ganadores de cada partida serán seleccionados por 3 jueces
                      calificadores. La plataforma en la cual los artistas
                      dibujarán digitalmente será Aggie.io
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 04 Modal BLAST - Text--> */}
                {/* <!-- Step 05 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasDice} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">5</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          modificadores blast
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Los modificadores blast, buscan generar una mecánica de
                      juego mas interesante y divertida, que pondrá a prueba tus
                      habilidades creativas.
                    </p>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      - Cambia al color azul.
                      <br />
                      - Haz Flip Horizontally (H).
                      <br />
                      - Rota el canvas 90°
                      <br />
                      - Cambia a la herramienta de Lápiz (P) puedes modificarla.
                      <br />- Agrega fuego.
                    </p>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Estos modificadores solo estarán activos 3 minutos durante
                      la partida. Estos son un ejemplo, la lista final será
                      publicada el día de la competencia. Los modificadores se
                      seleccionarán aleatoriamente en algún momento sorpresa
                      durante la partida.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 05 Modal BLAST - Text--> */}
                {/* <!-- Step 06 Modal BLAST - Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasTrophy} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">6</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Premios
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      En la categoría SB Blast, podrá haber premios por parte de
                      patrocinadores, los cuales se irán anunciando conforme se
                      acerque la fecha.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 06 Modal BLAST - Text--> */}

                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={AdminRespondeL}
                        alt=""
                      />
                    </div>

                    {/* <!-- blast Modal - ANSWER--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-title text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        ¿Más dudas?
                        <br />
                        Escribe a nuestras Redes Sociales
                      </p>
                    </div>

                    <div className="col-lg-12">
                      {/* <!-- blast Modal - Title--> */}
                      <p
                        className="blast-modal-titleOrange text-uppercase my-4"
                        id="BlastModalLabel"
                      >
                        ¿Estas listo o lista?
                      </p>
                    </div>

                    <div className="col-lg-12">
                    <a
                      className="btn btn-dark rounded-pill mb-2"
                      href="https://sketch-battle-blast-2020.eventbrite.com.mx">
                        <span className="Home__cta text-uppercase">
                          Participa
                        </span>
                      </a>
                    </div>
                    <div className="col-lg-12">
                      <p className="text-Registro-12 pb-4">
                        Al hacer click en "Participar" certifico que tengo 16
                        años o más y acepto cumplir el reglamento.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*MODAL PRO style: Display:None; */}

        <div
          className="blast-modal modal fade"
          id="ProModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ProModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div>
                <img className="img-fluid" src={SBpicture02} alt="" />
              </div>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <div className="divider-custom-icon">
                    <FontAwesomeIcon icon={fasTimes} />
                  </div>
                  {/* <!-- <i className="fas fa-times"></i> --> */}
                </span>
              </button>
              <div className="modal-body text-center">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      {/* <!-- PRO Modal - Title--> */}
                      <p
                        className="blast-modal-title text-uppercase my-3"
                        id="BlastModalLabel"
                      >
                        sketch battle pro
                      </p>
                    </div>

                    {/* <!-- PRO Modal - QUESTION--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={PersonajeDudaM}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-titleOrange text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        Y ¿Cómo hago mi registro?
                        <br />
                        ¿Cuáles son las reglas?
                      </p>
                    </div>

                    {/* <!-- PRO Modal - ANSWER--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-title text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        Lee los siguientes pasos...
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={AdminRespondeR}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="divider-custom">
                  <div className="divider-custom-line"></div>
                  <div className="divider-custom-icon">
                    <FontAwesomeIcon icon={fasfaHandPD} />
                  </div>
                  <div className="divider-custom-line"></div>
                </div>

                {/* <!-- Step 01 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasUser} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">1</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Regístrate
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                    Registra tu entrada <a className="enlaces" href="https://sketch-battle-pro-2020.eventbrite.com.mx">aquí </a> 
                    (o haciendo click en el botón al final de estas instrucciones) y finaliza 
                    tu compra ($250 MXN) y tu registro llenando el formulario. 
                    Recibirás un correo electrónico de confirmación y tu número de folio/boleto.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 01 Modal PRO- Text--> */}

                {/* <!-- Step 02 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasUsers} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">2</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Seleccionados
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Hay un límite 32 participantes, por lo tanto solo hay 32
                      entradas para participar en la categoría SB Pro. Los cupos
                      se irán llenando conforme las personas vayan pagando.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 02 Modal PRO- Text--> */}

                {/* <!-- Step 03 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom mt-4 mb-3">
                    <div className="divider-custom-line2"></div>
                    <div>
                      <img src={MinilogoNa} alt="MiniLogoNa" />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">3</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Torneo
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Sketch Battle es un torneo de dibujo digital de 32
                      personas, con un sistema de eliminación y partidas de 4 a
                      2 personas. El torneo será transmitido en Facebook Live,
                      Instagram Live y/o Twitch.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 03 Modal PRO- Text--> */}

                {/* <!-- Step 04 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasHandshake} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">4</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          batalla
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Compite dibujando en digital, contra 1 o 3 adversarios, en
                      una partida de 12 minutos. En cada partida, se anuncia un
                      tema diferente, el cual tendrás que dibujar. El ganador o
                      ganadores de cada partida serán seleccionados por 3 jueces
                      calificadores. La plataforma en la cual los artistas
                      dibujarán digitalmente será Aggie.io La categoría SB Pro,
                      no cuenta con modificadores que alteren la partida.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 04 Modal PRO- Text--> */}

                {/* <!-- Step 05 Modal PRO- Text--> */}
                <div className="container">
                  <div className="divider-custom">
                    <div className="divider-custom-line2"></div>
                    <div className="divider-custom-icon">
                      <FontAwesomeIcon icon={fasTrophy} />
                    </div>
                    <div className="divider-custom-line2"></div>
                  </div>
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3 align-self-center text-center">
                      <Link className="btn btn-NumberSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalNumberStep">5</span>
                      </Link>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-9 col-9 align-self-center text-center">
                      <Link className="btn btn-TextSteps rounded-pill noclick" to="/">
                        <span className="Home__ModalTextStep text-uppercase">
                          Premios
                        </span>
                      </Link>
                    </div>
                    <p className="Home__ModalText col-lg-12 col-md-12 col-sm-12 col-12 align-self-center pt-3 mb-0">
                      Los premios para la categoría SB Pro, es un premio mayor
                      para el primer lugar de $4000 MXN. Sin embargo, puede
                      haber premios extra por parte de patrocinadores, estos se
                      irán anunciando conforme se acerque la fecha de la
                      competencia.
                    </p>
                  </div>
                </div>
                {/* <!-- END Step 04 Modal PRO- Text--> */}

                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <img
                        className="img-fluid mt-3"
                        src={AdminRespondeL}
                        alt=""
                      />
                    </div>

                    {/* <!-- PRO Modal - ANSWER--> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 align-self-center">
                      <p
                        className="blast-modal-title text-uppercase mt-3"
                        id="BlastModalLabel"
                      >
                        ¿Más dudas?
                        <br />
                        Escribe a nuestras Redes Sociales
                      </p>
                    </div>

                    <div className="col-lg-12">
                      {/* <!-- PRO Modal - Title--> */}
                      <p
                        className="blast-modal-titleOrange text-uppercase my-4"
                        id="BlastModalLabel"
                      >
                        ¿Estas listo o lista?
                      </p>
                    </div>

                    <div className="col-lg-12">
                    <a
                      className="btn btn-dark rounded-pill mb-2"
                      href="https://sketch-battle-pro-2020.eventbrite.com.mx">
                        <span className="Home__cta text-uppercase">
                          Participa
                        </span>
                      </a>
                    </div>
                    <div className="col-lg-12">
                      <p className="text-Registro-12 pb-4">
                        Al hacer click en "Participar" certifico que tengo 18
                        años o más y acepto cumplir el reglamento.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
