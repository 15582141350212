import React, { Component } from "react";
import emailjs from "@emailjs/browser";

import "./styles/Registro.Usuario.css";

export default class RegistroUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensaje: "",
      nombre: "",
      remitente: "",
      enviado: false,
      botonTexto: "ENVIAR",
      isHidden: true,
      isMail: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    if (e.target.id === "mensaje") {
      this.setState({
        mensaje: e.target.value,
      });
    } else if (e.target.id === "nombre") {
      this.setState({
        nombre: e.target.value,
      });
    } else if (e.target.id === "remitente") {
      this.setState({
        remitente: e.target.value,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (
      this.state.nombre === "" ||
      this.state.mensaje === "" ||
      this.state.remitente === ""
    ) {
      return this.setState({ isHidden: false });

    } else if (!pattern.test(this.state.remitente)) {
      return this.setState({ isHidden: true, isMail: false });
      
    } else {
      this.setState({ isHidden: true, isMail: true });
      const serviceId = process.env.REACT_APP_SERVICE;
      const templateId = process.env.REACT_APP_TEMPLATE;
      const userId = process.env.REACT_APP_USERID;

      this.sendFeedback(
        serviceId,
        templateId,
        {
          nombre: this.state.nombre,
          message: this.state.mensaje,
          correo: this.state.remitente,
        },
        userId
      );
    }
  }

  sendFeedback(serviceId, templateId, variables, userId) {
    emailjs
      .send(serviceId, templateId, variables, userId)
      .then((res) => {
        //console.log("Email successfully sent!");
      })
      .then(
        this.setState({
          enviado: true,
          botonTexto: "ENVIADO!",
        })
      )
      // Handle errors here however you like, or use a React error boundary
      .catch((err) =>
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        )
      );
  }

  render() {
    return (
      <header className="masthead__nosotros">
        <div className="Home text-center" id="page-top">
          <div className="Nosotros__Titulo align-items-center justify-content-center text-center">
            <p className="Home__parrafoNegro text-uppercase mb-1">Contacto</p>
            <p className="text-Registro-14 mt-1" id="NombreDePerfil">
              Sugerencias, comentarios o cualquier cosa que nos quieras platicar
              <br />
              puedes rellenar el siguiente formulario.
            </p>
          </div>

          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center text-center">
              <div className="col-lg-1"> </div>

              <div className="col-lg-10 align-self-center">
                <div className="bg-registro text-center project shadow-lg my-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8 mx-auto">
                        <form
                          id="contactForm"
                          name="sentMessage"
                          noValidate="novalidate"
                        >
                          <div className="control-group">
                            <div className="form-group floating-label-form-group controls my-2 pb-0">
                              <label>Ingresa tu nombre</label>
                              <input
                                className="form-control"
                                id="nombre"
                                type="text"
                                placeholder="Nombre"
                                data-validation-required-message="Ingresa tu nombre"
                                onChange={this.handleChange}
                                required
                                disabled={this.state.enviado}
                                value={this.state.nombre}
                              />
                              {/* <p className="help-block text-danger"></p> */}
                            </div>
                          </div>
                          <div className="control-group">
                            <div className="form-group floating-label-form-group controls my-2 pb-0">
                              <label>Ingresa tu correo electrónico</label>
                              {!this.state.isMail && <IsMail />}
                              <input
                                className="form-control"
                                id="remitente"
                                placeholder="Correo electrónico"
                                data-validation-required-message="Ingresa un correo válido"
                                onChange={this.handleChange}
                                required
                                disabled={this.state.enviado}
                                value={this.state.remitente}
                              />
                              {/* <p className="help-block text-danger"></p> */}
                            </div>
                          </div>

                          <div className="form-group mt-4">
                            <p
                              className="text-Registro-12 text-left pb-1"
                              htmlFor="exampleFormControlTextarea1"
                            >
                              Mensaje
                            </p>
                            <textarea
                              className="form-control"
                              id="mensaje"
                              rows="3"
                              onChange={this.handleChange}
                              placeholder="Escribe aquí tus dudas o sugerencias"
                              required
                              disabled={this.state.enviado}
                              value={this.state.mensaje}
                            ></textarea>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">

                            <button
                              className="btn btn-dark rounded-pill my-4"
                              onClick={this.handleSubmit}
                              disabled={this.state.enviado}
                            >
                              <span className="Home__ctaRegistrarse text-uppercase">
                                {this.state.botonTexto}
                              </span>
                            </button>

                            {!this.state.isHidden && <Child />}

                          </div>

                          <div id="success"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"> </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const Child = () => (
  <p
    className="text-Registro-14 text-center my-8"
    htmlFor="exampleFormControlTextarea1"
  >
    <strong>Por favor, llene todos los campos</strong>
  </p>
);

const IsMail = () => (
  <p
    className="text-Registro-12 text-left pb-1"
    htmlFor="exampleFormControlTextarea1"
  >
    <strong>Por favor, escriba un correo valido</strong>
  </p>
);
