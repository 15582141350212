import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './Layout';
import Home from '../pages/Home';

import SeleccionSB from '../pages/Sketchbattle.Seleccion';
import Nosotros from '../pages/Sketchbattle.Nosotros';
import Contacto from '../pages/Sketchbattle.Contacto';
import Reglas from '../pages/Sketchbattle.Reglas';
import FAQ from '../pages/Sketchbattle.FAQ';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/enter-sketchbattle" element={<SeleccionSB/>} />
          <Route exact path="/nosotros" element={<Nosotros/>} />
          <Route exact path="/contacto" element={<Contacto/>} />
          <Route exact path="/reglas" element={<Reglas/>} />
          <Route exact path="/faq" element={<FAQ/>} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;